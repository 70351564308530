import React from "react";
import { Router } from "@gatsbyjs/reach-router";

import clientConfig from "../../client-config";
import PageHelmet from "../components/PageHelmet";
import Layout from "../containers/Layout";
import HomePage from "../components/HomePage";
import InfoPage from "../components/InfoPage";
import FaqPage from "../components/FaqPage";
import BlogPost from "../components/BlogPost";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
} from "../lib/helpers";

const sanityClient = require("@sanity/client");

const client = sanityClient({
  ...clientConfig.sanity,
  useCdn: false, // `false` if you want to ensure fresh data
  withCredentials: true,
});

interface IProps {
  document: any;
}

interface IState {
  document: any;
  type: string;
}
class PreviewPage extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      type: null,
    };
  }
  componentDidMount() {
    const queryType = `*[_id == "${this.props.document}"]  {
      _type
    }`;
    const queryAllFields = `*[_id == "${this.props.document}"]  {
        ...,
      }`;

    const queryInfoPage = `
    {
      'site': *[_id == "siteSettings"]  {
        ...,
      },
      'infoPage': *[_id == "infoPage"]  {
        ...,
        "brandVisual" : brandVisual[] {
          ...,
          _type == 'lottie' => {
            lottieFile{
              asset->{
                url
              }
            }
          },
          _type == 'videoFile' => {
            asset->{
              url
            }
          }
        }
      }
    }
    `;
    const queryHomePage = `
    {
      'site': *[_id == "siteSettings"]  {
        ...,
      },
      'posts': *[_type == "post"] | order(publishedAt desc),
      'homepage': *[_id == "homePage"]  {
        ...,
        heroVideoDesktop{
          asset->{
            url
          }
        },
        heroVideoMobile{
          asset->{
            url
          }
        },
      }
    }
    `;
    const queryPost = `*[_id == "${this.props.document}"]  {
      ...,
        "content" : content[] {
          ...,
          _type == 'videoFile' => {
              asset->{
                url
            }
          },
        }
      }`;
    client
      .fetch(queryType)
      .then((response) => {
        this.setState({
          type: response[0]._type,
        });
        switch (response[0]._type) {
          case "homePage":
            client.fetch(queryHomePage).then((res) => {
              this.setState({
                document: res,
              });
            });
            break;
          case "infoPage":
            client.fetch(queryInfoPage).then((res) => {
              this.setState({
                document: res,
              });
            });
            break;
          case "post":
            client.fetch(queryPost).then((res) => {
              this.setState({
                document: res[0],
              });
            });
            break;
          default:
            client.fetch(queryAllFields).then((res) => {
              this.setState({
                document: res[0],
              });
            });
            return;
        }
      })
      .catch((error) => {
        console.log("problem", error);
      });
  }
  renderPreview() {
    if (this.state.document) {
      const {
        tiltifyAccessToken,
        tiltifyApiEndpoint,
        tiltifyAmountRaisedOverride,
        tiltifyExtraAmountRaised,
        tiltifyTotalDonationTargetOverride,
        donationUrl,
        ticketsUrl,
        streamUrl,
        streamathonLabel,
        streamathonDate,
        lanLabel,
        lanDate,
      } = this.state.document.site ? this.state.document.site[0] : "";

      const globalUrls = { donationUrl, ticketsUrl, streamUrl };
      switch (this.state.type) {
        case "homePage":
          return (
            <HomePage
              {...this.state.document.homepage[0]}
              globalUrls={globalUrls}
              tiltifyConfig={{
                tiltifyAccessToken,
                tiltifyApiEndpoint,
                tiltifyAmountRaisedOverride,
                tiltifyExtraAmountRaised,
                tiltifyTotalDonationTargetOverride,
              }}
              streamathonLabel={streamathonLabel}
              streamathonDate={streamathonDate}
              lanLabel={lanLabel}
              lanDate={lanDate}
              posts={this.state.document.posts
                .filter(filterOutDocsWithoutSlugs)
                .filter(filterOutDocsPublishedInTheFuture)}
            />
          );
        case "infoPage":
          return (
            <InfoPage
              {...this.state.document.infoPage[0]}
              globalUrls={globalUrls}
              streamathonLabel={streamathonLabel}
              streamathonDate={streamathonDate}
              lanLabel={lanLabel}
              lanDate={lanDate}
            />
          );
        case "faqPage":
          return <FaqPage {...this.state.document} />;
        case "post":
          return <BlogPost {...this.state.document} />;
        default:
          return (
            <h1
              style={{
                textAlign: "center",
                background: "black",
                color: "white",
                padding: "20rem 0",
              }}
            >
              Yikes! This page type has no preview available.
            </h1>
          );
          break;
      }
    }
  }
  render() {
    return (
      <Layout>
        <PageHelmet title={`Preview ${this.state.type}`} />
        {this.renderPreview()}
      </Layout>
    );
  }
}

const Preview = () => {
  return (
    <div>
      <Router>
        {/* @ts-ignore */}
        <PreviewPage path="/preview/:document" />
      </Router>
    </div>
  );
};

export default Preview;
